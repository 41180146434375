import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/research">Research</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/teaching">Teaching</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/publications">Publications</Link></li>
                <li><a href="https://blog.jcamargo.co">Blog</a></li>
            </ul>
            <ul className="actions vertical">
                <li><a href="/cv" className="button special fit">CV</a></li>
                {/* <li><a href="#" className="button fit">Log In</a></li> */}
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
