import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://twitter.com/jcamargo_co" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/is.not.ossip" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.youtube.com/user/jcl087/videos" className="icon alt fa-youtube"><span className="label">Youtube</span></a></li>
                <li><a href="https://github.com/jonathancamargo" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/in/camargoj/" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; Jonathan Camargo 2021 </li>
                <li> Powered by Gatsby </li><li>Designed by <a href="https://html5up.net">HTML5 UP</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
